<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Покупка</p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <b-field label="Телефон">
        <b-input type="input" v-model="tel" required> </b-input>
      </b-field>

      <b-field label="Ваше имя">
        <b-input type="input" v-model="name" required></b-input>
      </b-field>

      <b-checkbox v-model="accept"
        >Согласен на обработку персональных данных</b-checkbox
      >
    </section>
    <footer class="modal-card-foot">
      <b-button label="Закрыть" @click="$emit('close')" />
      <b-button
        label="Отправить заявку"
        type="is-success"
        @click="send()"
        :loading="loading"
      />
    </footer>
  </div>
</template>

<script>
import { HTTP } from "../../http-common";

export default {
  name: "BuyModal",
  props: ["product"],
  data() {
    return {
      name: "",
      tel: "",
      accept: false,
      loading: false,
      productId: this.productId,
    };
  },
  methods: {
    send() {
      let valid = true;
      if (this.name == "") {
        valid = false;
      }
      if (this.tel == "") {
        valid = false;
      }
      if (!this.accept) {
        valid = false;
      }
      if (!valid) {
        this.$buefy.toast.open({
          type: "is-danger",
          message: "Заполните все поля",
          duration: 1000,
        });
        return;
      }
      HTTP.post("/shop/buy", {
        name: this.name,
        tel: this.tel,
        productId: this.product,
      })
        .then(() => {
          this.$buefy.toast.open({
            type: "is-success",
            message: "Ваша заявка отправлена !",
            duration: 5000,
          });
          this.$emit("close");
        })
        .catch(() => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: "Ой, что-то пошло не так..",
            duration: 1000,
          });
        });
    },
  },
};
</script>
