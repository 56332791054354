<template>
  <section class="container" style="padding-top: 25px" v-if="!loading">
    <nav class="breadcrumb" aria-label="breadcrumbs" style="padding: 0 10px">
      <ul>
        <li><router-link to="/">Интернет-Магазин</router-link></li>
        <li>
          <router-link
            :to="{
              name: 'category',
              params: { alias: product.category_alias },
            }"
            >{{ product.category_name }}</router-link
          >
        </li>
        <li class="is-active">
          <a href="#" aria-current="page">{{ product.name }}</a>
        </li>
      </ul>
    </nav>
    <div class="columns" style="padding: 0 10px">
      <div class="column" style="text-align: center">
        <img :src="uploads_url + activePhoto" alt="photo" />
        <div class="subPhotos">
          <img
            :src="uploads_url + pic.url"
            v-for="pic in productPhotos"
            :key="pic.id"
            alt="pic"
            style="height: 100px"
            @click="activePhoto = pic.url"
          />
        </div>
      </div>
      <div class="column">
        <h1 class="title">{{ product.name }}</h1>
        <div class="short_description" style="margin: 1rem 0; font-size: 14px">
          {{ product.short_description }}
        </div>
        <div class="price" style="font-size: 22px; margin-bottom: 1rem">
          Цена: <b>{{ product.price }} ₽</b>
        </div>
        <b-button type="is-warning" icon-left="shopping-cart" @click="buy()"
          >Купить</b-button
        >
        <div class="microtext" style="margin-top: 1rem; font-size: 13px">
          *На товар распространяется годовая гарантия
        </div>
      </div>
    </div>
    <div class="tabs" style="width: 100%">
      <b-tabs v-model="activeTab" style="width: 100%" type="is-boxed">
        <b-tab-item label="Описание">
          <div v-html="product.description"></div>
        </b-tab-item>
        <b-tab-item label="Характеристики">
          <div class="props-list">
            <div class="prop-item" v-for="item in productProps" :key="item.id">
              <div class="prop-item-name">{{ item.name }}</div>
              <div class="prop-item-sep"></div>
              <div class="prop-item-value">{{ item.value }}</div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Коды ошибок">
          <div v-html="product.errors"></div>
        </b-tab-item>
        <b-tab-item label="Скачать" v-if="productFiles && productFiles.length">
          <div class="files-list">
            <div
              class="files-list-item"
              v-for="item in productFiles"
              :key="item.id"
            >
              <span>{{ item.title }}</span>
              <a class="button is-small" :href="uploads_url + item.filename"
                >скачать</a
              >
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Отзывы">
          <div
            class="reviews-list"
            v-if="productReviews && productReviews.length"
          >
            <div
              class="reviews-list-item"
              v-for="item in productReviews"
              :key="item.id"
            >
              <b>Автор:</b> {{ item.name }} <br />
              <b>Отзыв:</b> {{ item.message }}
            </div>
          </div>
          <p v-if="productReviews == null">
            У этого товара пока нет отзывов...
          </p>
          <p style="font-weight: bold; margin: 2rem 0">Оставить свой отзыв:</p>
          <div class="form">
            <b-field label="Ваше имя">
              <b-input v-model="review.name" />
            </b-field>
            <b-field label="Ваш e-mail">
              <b-input type="email" v-model="review.email" />
            </b-field>
            <b-field label="Оценка">
              <star-rating
                :star-size="25"
                v-model="review.rating"
              ></star-rating>
            </b-field>
            <b-field label="Сообщение">
              <b-input type="textarea" v-model="review.message" />
            </b-field>
            <div class="buttons">
              <b-button
                type="is-warning"
                :loading="review.loading"
                @click="sendReview()"
                >Отправить</b-button
              >
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
    <b-loading v-model="loading" :is-full-page="true"></b-loading>

    <h1
      class="title-custom"
      style="margin-top: 2rem"
      v-if="relatedProducts && relatedProducts.length"
    >
      Рекомендуемые товары
    </h1>

    <dir class="related" v-if="relatedProducts && relatedProducts.length">
      <div
        class="related-product"
        v-for="item in relatedProducts"
        :key="item.id"
      >
        <a
          :href="
            $router.resolve({ name: 'product', params: { alias: item.alias } })
              .href
          "
        >
          <img
            :src="uploads_url + item.photo_url"
            alt="pic"
            style="height: 200px"
          />
          <p>{{ item.name }}</p>
        </a>
      </div>
    </dir>

    <h1 class="title-custom" style="margin-top: 3rem">
      ПОЧЕМУ КУПИТЬ ОНЛАЙН-КАССЫ НУЖНО ИМЕННО У НАС
    </h1>

    <section class="section advantages">
      <div class="columns">
        <div class="column">
          <b-icon icon="money-bill-alt" />
          <h3 class="icon-box-title">
            <span>СТОИМОСТЬ<br /><br /></span>
          </h3>
          <p class="icon-box-description">
            У нас самые лояльные цены на рынке кассовой техники в Орле
          </p>
        </div>
        <div class="column">
          <b-icon icon="shopping-cart" />
          <h3 class="icon-box-title">
            <span>УДОБСТВО<br /><br /></span>
          </h3>
          <p class="icon-box-description">
            Вам не нужно ехать за кассовым аппаратом, мы сами привезем его к Вам
            в офис
          </p>
        </div>
        <div class="column">
          <b-icon icon="smile-wink" />
          <h3 class="icon-box-title">
            <span>БЕСПЛАТНАЯ РЕГИСТРАЦИЯ</span>
          </h3>
          <p class="icon-box-description">
            При покупке нового кассового аппарата регистрация в налоговой
            бесплатно
          </p>
        </div>
        <div class="column">
          <b-icon icon="percent" />
          <h3 class="icon-box-title">
            <span>СКИДКИ<br /><br /></span>
          </h3>
          <p class="icon-box-description">
            Для клиентов, заключивших договор на сервисное обслуживание цены еще
            ниже
          </p>
        </div>
      </div>
    </section>
    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Покупка"
      aria-modal
    >
      <template #default="props">
        <buy-modal @close="props.close" v-bind:product="product.id"></buy-modal>
      </template>
    </b-modal>
  </section>
</template>

<style>
.tab-item ul {
  line-height: 1.1;
  padding-left: 10px;
  list-style-type: none;
  list-style-position: inside;
  display: block;
  border: none;
}
.tab-item ul li {
  padding-left: 10px;
  background: url("/caret-right-solid.svg") no-repeat center left;
}
.tab-content p {
  white-space: break-spaces;
}
.advantages .icon {
  font-size: 3rem;
  color: #f7a31d;
}
.advantages .column {
  text-align: center;
}
.advantages h3 {
  color: #33494d;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin: 1rem 0;
}
.tabs ul li a {
  color: #8e44ad;
}
.subPhotos {
  display: flex;
  flex-direction: row;
}
.subPhotos img {
  opacity: 0.8;
  margin-left: 5px;
  border-radius: 3px;
}
.subPhotos img:first-child {
  margin-left: 0;
}
.subPhotos img:hover {
  cursor: pointer;
  opacity: 1;
}
@media screen and (max-width: 900px) {
  .container {
    width: 100%;
    overflow: hidden;
  }
  .tabs a {
    font-size: 14px;
    color: #8e44ad;
  }
}
.related {
  display: flex;
  flex-direction: row;
}
.related-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: 1rem;
}
.related-product:first-child {
  margin-left: 0;
}
.related-product img {
  border-radius: 4px;
}
.related-product:hover img {
  opacity: 0.8;
}
.props-list {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}
.prop-item {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.prop-item-value {
  font-weight: bold;
}
.prop-item-sep {
  flex-grow: 1;
  margin: 0 4px;
  border-bottom: 1px #cccccc dotted;
}
.files-list {
  display: flex;
  flex-direction: column;
}
.files-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  margin: 0.5rem;
  border: 1px #bdc3c7 dashed;
}
.reviews-list-item {
  padding: 0.5rem;
  margin: 0.5rem;
  border-bottom: 1px #bdc3c7 dashed;
}
</style>

<script>
import { HTTP } from "../../http-common";
import BuyModal from "./BuyModal";
import StarRating from "vue-star-rating";

export default {
  components: { BuyModal, StarRating },
  data() {
    return {
      uploads_url: process.env.VUE_APP_IMAGES_SERVER_URL,
      product: null,
      loading: false,
      activeTab: 0,
      isComponentModalActive: false,
      productPhotos: [],
      activePhoto: null,
      relatedProducts: [],
      productProps: [],
      productFiles: [],
      productReviews: [],
      review: {
        rating: 0,
        name: "",
        email: "",
        message: "",
        loading: false,
      },
    };
  },
  methods: {
    async loadProductData() {
      this.loading = true;
      await HTTP.get("/product/" + this.$route.params.alias).then((res) => {
        this.product = res.data;
        this.activePhoto = this.product.photo_url;
      });
      this.loading = false;
    },
    loadProductPhotos() {
      HTTP.get("/product/" + this.product.id + "/photo").then((res) => {
        this.productPhotos = res.data;
      });
    },
    loadProductRelated() {
      HTTP.get("/product/" + this.product.id + "/related").then((res) => {
        this.relatedProducts = res.data;
      });
    },
    loadProductProps() {
      HTTP.get("/product/" + this.product.id + "/prop").then((res) => {
        this.productProps = res.data;
      });
    },
    loadProductFiles() {
      HTTP.get("/product/" + this.product.id + "/file").then((res) => {
        this.productFiles = res.data;
      });
    },
    loadProductReviews() {
      HTTP.get("/product/" + this.product.id + "/review").then((res) => {
        this.productReviews = res.data;
      });
    },
    buy() {
      this.isComponentModalActive = true;
    },
    sendReview() {
      this.review.loading = true;
      let formData = new FormData();
      formData.append("product_id", this.product.id);
      formData.append("name", this.review.name);
      formData.append("email", this.review.email);
      formData.append("rating", this.review.rating);
      formData.append("message", this.review.message);
      HTTP.post("/product/" + this.product.id + "/review", formData)
        .then(() => {
          this.$buefy.toast.open("Отзыв отправлен !");
          this.review.name = "";
          this.review.email = "";
          this.review.message = "";
          this.review.rating = 0;
          this.review.loading = false;
        })
        .catch(() => {
          this.$buefy.toast.open("ошибка");
        });
    },
  },
  async created() {
    await this.loadProductData();
    this.loadProductPhotos();
    this.loadProductRelated();
    this.loadProductProps();
    this.loadProductFiles();
    this.loadProductReviews();
  },
};
</script>
